import mixpanel from 'mixpanel-browser';
// import { useParams } from 'react-router';

mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_KEY, {debug: true}); 



export default async function analytic(props) {
  const {key,value} = props
  console.log(key,props,":::::");
  console.log(window.location.hash);
  const [hash, query] = window.location.href.split('#')[1].split('?')
  const params = Object.fromEntries(new URLSearchParams(query))
  
  console.log(hash)
  const Source =params.source


const batchData = {...value,event:process.env.REACT_APP_EVENT_NAME,Source}   
console.log(batchData); 
  await mixpanel.track(key,batchData)

}