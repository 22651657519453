/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Row, Col, Button, Space, notification } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'

import { Link } from 'react-router-dom'
import styles from 'components/LayoutComponents/Virtual/streamFeatures2/lenovoHome/style.module.scss'
import Agenda from 'components/LayoutComponents/Virtual/streamFeatures2/lenovoHome/agenda'
// import { FormattedMessage } from 'react-intl'
import { history } from 'index'
import { connect } from 'react-redux'
import qs from 'qs'
import About from 'components/LayoutComponents/Virtual/streamFeatures2/lenovoHome/about'
import Speaker from 'components/LayoutComponents/Virtual/streamFeatures2/lenovoHome/speaker'
import Survey from 'components/LayoutComponents/Virtual/streamFeatures2/lenovoHome/survey'
// import Login from 'components/LayoutComponents/Virtual/streamFeatures2/lenovoHome/login'
import Login from 'pages/virtual/event/user/login'
import Iframe from 'react-iframe'
import { isMobile } from 'react-device-detect'

import { CloseCircleOutlined } from '@ant-design/icons'
import analytic from '../../../services/config/analytic'
/* eslint-disable */ 
// import styless from './style.module.scss'
// import Avatar from '../../../components/CleanUIComponents/Avatar'

// import Register from './register'
// function withHooksHOC(Component) {
//   return function WrappedComponent(props) {
//     console.log(props)
//     // Analytic('test')
//     return <Component />
//   }
// }

@connect(({ virtualUser, settings, locales, registerVirtualUser }) => ({
  virtualUser,
  settings,
  locales,
  registerVirtualUser,
}))
class LoginLayout extends React.PureComponent {
  constructor() {
    super()
    // this.handleSHow = this.handleShow.bind(this)
    this.state = {
      select: '',
      child: '',
      modalState: false,
      // deadline: 'September, 29, 2022 12:00',
    }
  }

  componentDidMount() {
    const analyticData = {
      key: 'home',
      value: {},
    }
    analytic(analyticData)
    this.setState({
      modalState: true,
    })
    const { dispatch } = this.props
    const query = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    // localStorage.setItem('source', query)
    console.log(query, 'query')
    if (query.source !== undefined) {
      console.log(query, 'query')
      dispatch({
        type: 'registerVirtualuser/SET_STATE',
        payload: {
          source: query.source,
        },
      })
    }
    if (query.form === 'survey') {
      console.log(query, 'query')
      dispatch({
        type: 'registerVirtualuser/SET_STATE',
        payload: {
          surevySource: query.form,
        },
      })
    }
  }
  // changeLanguage = theLanguage => {
  //   const { dispatch } = this.props
  //   dispatch({
  //     type: 'locales/SET_STATE',
  //     payload: {
  //       selectedLocale: theLanguage,
  //     },
  //   })
  // }

  selected = event => {
    const analyticData = {
      key: event,
      value: {},
    }
    analytic(analyticData)
    console.log(event)

    // const { deadline } = this.state
    const {
      children,
      dispatch,
      registerVirtualUser: { source },
    } = this.props
    console.log(children, 'children')
    // const streamType = event.target.getAttribute('data-type')
    console.log(event, '123')
    if (event === 'home') {
      // this.setState({ child: <Login selected={this.selected} /> })
      this.setState({ select: event, child: <Login selected={this.selected} onSelected={this.selected}  source={source} /> })
    }
    if (event === 'register') {
      // this.setState({ child:  })
      this.setState({ select: event })
    }
    if (event === 'login') {
      // this.setState({ child: <Login /> })
      this.setState({ select: event })
    }
    if (event === 'agenda') {
      this.setState({ child: <Agenda selected={this.selected} source={source} /> })
      this.setState({ select: event })
    }
    if (event === 'about') {
      this.setState({ child: <About selected={this.selected} source={source} /> })
      this.setState({ select: event })
    }
    if (event === 'speaker') {
      this.setState({ child: <Speaker selected={this.selected} source={source} /> })
      this.setState({ select: event })
    }
    if (event === 'survey') {
      this.setState({ child: <Survey selected={this.selected} /> })
      this.setState({ select: event })
    }
    if (event === 'game') {
      this.setState({
        child: (
          <Row style={{ margin: '20px' }}>
            <Col span={24} style={{ textAlign: 'end' }}>
              <Button
                icon={<CloseCircleOutlined style={{ color: '#ffe600', fontSize: '25px' }} />}
                onClick={() => this.selected('home')}
              />
            </Col>
            <Col span={24} style={{ marginRight: '20px' }}>
              <Iframe
                url="https://wiz-penalty-kicks.netlify.app/"
                width="100%"
                height="calc(100vh - 200px)"
                loading="eager"
                className="theiFrame"
                display="initial"
                position="relative"
                frameborder="0"
              />
            </Col>
          </Row>
        ),
      })
    }

    dispatch({
      type: 'registerVirtualuser/SET_STATE',
      payload: {
        surevySource: '',
      },
    })
    // if (event === 'register') {
    //   this.setState(true)
    // }
    return ''
  }

  handleOk = () => {
    this.setState({ modalState: false })
  }

  handleCancel = () => {
    this.setState({ modalState: false })
  }

  showNotif = () => {
    const {
      registerVirtualUser: { source },
    } = this.props
    if (source === 'ANZ-ANZ' || source === 'ANZ-AMD' || source === 'ANZ-MARKETO' || source === 'ANZ-SOCIAL') {
      notification.open({
        message: 'The event will be live 01:00 PM AEDT, on 23rd Feb 2023. Kindly revisit.',
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    }
    else {
      notification.open({
        message: 'The event will be live at 11:30 AM IST | 02:00 PM SGT, on 22nd Feb 2023. Kindly revisit.',
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    }
  }

  // localStorage.setItem('location', query.city))

  render() {
    // const srce = localStorage.getItem('source', query.source)

    // console.log(query, 'query')
    console.log('changeslayout')


    const { child, select, modalState } = this.state
    const {
      children,
      // virtualUser,
      registerVirtualUser: { surevySource, source },

      locales: {
        selectedLocale,
        availableLocales,
        //  isLocaleSwitcher
      },
    } = this.props
    // if (query !== "") {
    //   // this.setState({ child: <Login deadline={deadline} /> })
    //   this.setState({ select: 'register' })
    // }
    if (select === '') {
      this.setState({ child: <Login selected={this.selected} onSelected={this.selected} /> })
      this.setState({ select: 'home' })
    }
    const lang = availableLocales[selectedLocale]

    const selectedLang = lang.associatedLocale.messages
    console.log(lang, selectedLang, 'llang')
    // const languages = Object.entries(availableLocales).map(key => {
    //   console.log(key)
    //   return (
    //     <Menu.Item onClick={() => this.changeLanguage(key[0])} key={key[0]}>
    //       <Row>
    //         {/* <Col span={5} className="text-center">
    //           <img src={key[1].icon} alt={key[1].name} style={{ width: '70%' }} />
    //         </Col>
    //         <Col span={1} /> */}
    //         <Col span={24}>
    //           <p style={{ fontWeight: 'bold' }}>{key[1].name}</p>
    //         </Col>
    //       </Row>
    //     </Menu.Item>
    //   )
    // })

    // const langSelector = (
    //   <Menu
    //     selectable
    //     style={{ width: 150 }}
    //     placement="bottomLeft"
    //     selectedKeys={[selectedLocale]}
    //   >
    //     {languages}
    //   </Menu>
    // )
    console.log(modalState, 'modalState')

    return (
      <>
        {/* {modalState ? ( */}
        {/* {isMobile ? (
          <Modal
            // maskStyle={{ backgroundColor: 'rgba(208,196,232, .6)', color: 'black' }}
            className="modalStyle"
            title="Note"
            visible={modalState}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
            closeIcon={
              <Tooltip title="Close">
                <CloseCircleOutlined
                  style={{ color: '#7A126B', fontSize: '25px', marginBottom: '15px' }}
                />
              </Tooltip>
            }
            destroyOnClose
            centered
          >
            <h4>Please use the landscape mode for better experience</h4>
          </Modal>
        ) : (
          ''
        )} */}

        {/* ) : null} */}
        <div
          style={{
            backgroundImage: isMobile
              ? `url('resources/images/backwallMOb.jpg')`
              : `url('resources/images/backg.jpg')`,
            // maxWidth: '100%',
            // marginLeft: '-5px',
            // marginRight: '-5px',
            // overflow: 'hidden',
            // bottom: '0px',
            // maxHeight:"100%"\
            // textAlign:'center',
            backgroundPosition: 'center',
          }}
        >
          <Col span={24}>
            <Scrollbars
              // autoHeight
              // autoHeightMin="100vh"
              // autoHeightMax={`calc(100vh - ${isMobile ? '2rem' : '0px'})`}
              style={{ height: `calc(100vh - ${isMobile ? '5rem' : '0px'})` }}
            >
              <Row className={styles.container}>
                <Col span={24} style={{ overflow: 'hidden' }}>
                  <img
                    src={
                      isMobile ? 'resources/images/bannerMOB.png' : 'resources/images/banner.jpg'
                    }
                    alt="banner"
                    //   style={{ width: '100%', height: '130px' }}
                    className={styles.banner}
                  />
                </Col>
                {/* <Row style={{ overflow: 'hidden' }}> */}
                {/* <Col span={5}>
                  <Navbar select={select} selected={selected} />
                </Col> */}

                {/* <Col span={24} style={{ textAlign: 'end', margin: '5px' }}>
                  {' '}
                  {!isLocaleSwitcher ? (
                    <Tooltip title="Change Language" placement="left">
                      <Dropdown
                        overlay={langSelector}
                        trigger={['click']}
                        className="pull-right"
                        // style={{ position: 'absolute', top: 0, marginRight: '5px' }}
                      >
                        <div className={styles.dropdown} style={{ marginRight: '20px' }}>
                          <Avatar
                            src={availableLocales[selectedLocale].icon}
                            border
                            borderColor="white"
                            size="10"
                          />
                          <Button
                            style={{
                              backgroundColor: '#46C8E1',
                              fontSize: '14px',
                              borderRadius: '20px',
                              padding: '5px',
                              height: '35px',
                              width: '90px',
                              color: 'black',
                              fontWeight: 'bold',
                            }}
                          >
                            Language
                          </Button>
                        </div>
                      </Dropdown>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </Col> */}
                <Col span={24} style={{ marginTop: '20px' }}>
                  <Row>
                    <Col lg={5} xs={24} xl={5} style={{display:'none'}}>
                      <Row>
                        <Col
                          span={20}
                          style={{ margin: '0px 0px 0px 40px' }}
                          className="home_features2"
                        >
                          <Row>
                            <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                              <Col span={20} onClick={() => this.selected('home')}>
                                <img
                                  src="resources/images/home.png"
                                  data-type="qanda"
                                  className="messageIcon"
                                  alt="Q&amp;A"
                                // onClick={this.handleOpenClose}
                                />
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    color: 'black',
                                    marginLeft: '5px',
                                  }}
                                >
                                  {lang.name === 'Japanese' ? selectedLang.home : 'Home'}
                                </span>
                              </Col>
                              <Col span={24} onClick={() => this.selected('about')}>
                                <img
                                  src={
                                    select !== 'about'
                                      ? 'resources/images/about.png'
                                      : 'resources/images/Aboutselect.png'
                                  }
                                  className="messageIcon"
                                  alt="Q&amp;A"
                                // onClick={this.handleOpenClose}
                                />
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    marginLeft: '5px',
                                    color: 'black',
                                  }}
                                >
                                  {lang.name === 'Japanese'
                                    ? selectedLang.about
                                    : 'About the Event'}
                                </span>
                              </Col>

                              <Col span={24} onClick={() => this.selected('agenda')}>
                                <img
                                  src={
                                    select !== 'agenda'
                                      ? 'resources/images/Agenda2.png'
                                      : 'resources/images/Agendaselect.png'
                                  }
                                  // src={
                                  //   select !== 'agenda'
                                  //     ? 'resources/images/Agenda2.png'
                                  //     : 'resources/images/Agenda2.png'
                                  // }
                                  // data-type="qanda"
                                  className="messageIcon"
                                  alt="Q&amp;A"
                                // onClick={this.handleOpenClose}
                                />
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    marginLeft: '5px',
                                    color: 'black',
                                  }}
                                >
                                  {lang.name === 'Japanese' ? selectedLang.agenda : 'Agenda'}
                                </span>
                              </Col>

                              <Col span={20} onClick={() => this.selected('speaker')}>
                                <img
                                  src={
                                    select !== 'speaker'
                                      ? 'resources/images/Speakers.png'
                                      : 'resources/images/Speakerselect.png'
                                  }
                                  // src={
                                  //   select !== 'speaker'
                                  //     ? 'resources/images/Speakerselect.png'
                                  //     : 'resources/images/Speakerselect.png'
                                  // }
                                  className="messageIcon"
                                  alt="Q&amp;A"
                                // onClick={this.handleOpenClose}
                                />
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    marginLeft: '5px',
                                    color: 'black',
                                  }}
                                >
                                  {lang.name === 'Japanese' ? selectedLang.speakers : 'Speakers'}
                                </span>
                              </Col>
                              {/* <Col onClick={() => this.selected('survey')}>
                                <img
                                  src={
                                    select !== 'survey'
                                      ? 'resources/images/survey.png'
                                      : 'resources/images/Surveyselect.png'
                                  }
                                  className="messageIcon"
                                  alt="Q&amp;A"
                                  // onClick={this.handleOpenClose}
                                />
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    marginLeft: '5px',
                                    color: 'black',
                                  }}
                                >
                                  {lang.name === 'Japanese' ? selectedLang.survey : 'Survey'}
                                </span>
                              </Col> */}
                              {/* <Col onClick={() => this.selected('game')}>
                          <img
                            src={
                              select !== 'survey'
                                ? 'resources/images/game.png'
                                : 'resources/images/game.png'
                            }
                            className="messageIcon"
                            alt="Q&amp;A"
                            // onClick={this.handleOpenClose}
                          />
                          <a
                            href="https://preview.codecanyon.net/item/penalty-kicks-html5-sport-game/full_screen_preview/18559450"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span
                              style={{
                                fontWeight: 'bold',
                                fontSize: '14px',
                                marginLeft: '5px',
                                color: 'black',
                              }}
                            >
                              {lang.name === 'Japanese' ? selectedLang.game : 'Game'}
                            </span>
                          </a>
                        </Col> */}

                              <Col
                                span={20}
                                onClick={() => {
                                  console.log('dsd')
                                  this.selected('register')
                                }}
                              >
                                {/* <img
                          src={
                            select !== 'survey'
                              ? 'resources/images/registration.png'
                              : 'resources/images/registration.png'
                          }
                          className="messageIcon"
                          alt="Q&amp;A"
                          // onClick={this.handleOpenClose}
                        /> */}
                                {/* <span style={{ fontWeight: 'bold', fontSize: '14px', marginLeft: '5px' }}> */}
                                {/* <Link
                                  to={
                                    source
                                      ? `/virtual/auth/register?source=${source}`
                                      : '/virtual/auth/register'
                                  }
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    color: 'black',
                                    textDecoration: 'none',
                                  }}
                                >
                                  <Button
                                    size="small"
                                    style={{
                                      backgroundColor: '#7A126B',
                                      fontSize: '14px',
                                      borderRadius: '10px',
                                      padding: '5px',
                                      height: '35px',
                                      width: '100px',
                                      color: 'white',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {' '}
                                    {lang.name === 'Japanese'
                                      ? selectedLang.register
                                      : 'Register Now'}
                                  </Button>
                                </Link> */}
                                {/* Register Now
                        </span> */}
                              </Col>
                              <Col
                                span={20}
                                onClick={() => this.selected('login')}
                              >
                                <Link
                                  to="/virtual/auth/login"
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    color: 'black',
                                    textDecoration: 'none',
                                  }}
                                >
                                  <Button
                                    size="small"
                                    // type="submit"
                                    // onClick={this.showNotif}
                                    style={{
                                      // margin: '10%',
                                      backgroundColor: '#7A126B',
                                      fontSize: '14px',
                                      borderRadius: '10px',
                                      padding: '5px',
                                      height: '30px',
                                      width: '60px',
                                      color: 'white',
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    {' '}
                                    {lang.name === 'Japanese' ? selectedLang.login : 'Login'}
                                  </Button>
                                </Link>
                                <br />
                              </Col>
                            </Space>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} xs={24} xl={6} />
                    <Col lg={12} xs={24} xl={12}>
                      {surevySource === 'survey' ? (
                        <Survey selected={this.selected} />
                      ) : (
                        <>{select === 'register' || select === 'login' ? children : child}</>
                      )}

                      {/* <Col lg={18} xs={24}>
                  {children}
                 </Col> */}
                      {/* <LoginLayout /> */}
                    </Col>
                    <Col lg={6} xs={24} xl={6} />
                  </Row>
                </Col>
                <Col
                  span={24}
                  style={{
                    backgroundColor: '#e7e7e7',
                    marginTop: '10px',
                    bottom: '0px',
                    // position: isMobile ? 'none' : 'fixed',
                  }}
                >
                  <a
                    href="https://www.lenovo.com/in/en/privacy/?orgRef=https%253A%252F%252Fna-ab27.marketodesigner.com%252F&mkt_tok=MTgzLVdDVC02MjAAAAGAp096QnbPWDljzKiyr0-y607yXHLBFhZUo-Aj6z5zfSzoaDXKju_vlVhytN85-MZn_ZKHREMuS5Uqg9_j4trV7B7bOWKHLdR4p4UrKYvaQcxh1G1i"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h5 style={{ color: 'black', textDecoration: 'underline', margin: '10px' }}>
                      Privacy Policy
                    </h5>
                  </a>
                  <p
                    style={{
                      marginLeft: '10px',
                      fontSize: '8px',
                      marginBottom: '0px',
                      paddingBottom: '10px',
                    }}
                  >
                    Lenovo reserves the right to alter product offerings or specifications at any
                    time without notice. Model pictures are for illustration purposes only. Lenovo
                    is not responsible for typographic or photographic errors. Information
                    advertised has no contractual effect. Lenovo may not offer the products,
                    services or features discussed in this document in all countries. Promotions are
                    for business customers only and are subject to availability. Promotions may be
                    withdrawn or changed without prior notice.
                    <br />
                    <br />
                    © Lenovo 2023. All rights reserved. Lenovo, the Lenovo logo, ThinkSystem and
                    ThinkAgile are trademarks of Lenovo.
                    <br />
                    AMD - © 2023 Advanced Micro Devices, Inc. All rights reserved. AMD, the Arrow
                    logo and combinations there of trademarks of Advanced Micro Devices, Inc. in the
                    United States and/or other jurisdictions.
                  </p>
                </Col>
                <Col span={24} />
              </Row>
            </Scrollbars>
          </Col>
        </div>
      </>
    )
  }
}
export default LoginLayout
