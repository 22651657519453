import React from 'react'
import { Row, Col } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
// import Iframe from 'react-iframe'
// import { Scrollbar } from 'react-scrollbars-custom'
// import ReactPlayer from 'react-player'
import { connect } from 'react-redux'
import actions from 'redux/virtual/event/actions'
import './quickview.less'

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

@connect(({ virtualEvent }) => ({ virtualEvent }))
class DocumentList extends React.Component {
  state = {
    numPages: null,
  }

  goBack = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isQuickViewOpen: false,
        currentFile: {},
      },
    })
  }

  onError = err => {
    console.log('error in opening file')
    console.log(err)
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  render() {
    console.log('i am in quickview')
    const {
      virtualEvent: { currentFile },
    } = this.props

    const { numPages } = this.state
    // const extension = currentFile.fileURL
    //   .substring(currentFile.fileURL.lastIndexOf('.') + 1)
    //   .toLowerCase()
    const extension = currentFile.fileURL
      .substring(currentFile.fileURL.lastIndexOf('.') + 1)
      .toLowerCase()

    const finalRender = []
    console.log(extension, finalRender, 'extension')
    if (extension === 'pdf') {
      finalRender.push(
        // <Iframe
        //   url={`https://docs.google.com/gview?url=${currentFile.fileURL}&embedded=true`}
        //   // url={currentFile.fileURL}
        //   width="100%"
        //   height="600px"
        //   id="myId"
        //   className="myClassname"
        //   display="initial"
        //   position="relative"
        // />,
        <div className="Example">
          <div className="Example__container">
            <div className="Example__container__document">
              <Document file={currentFile.fileURL} onLoadSuccess={this.onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
              ,
            </div>
          </div>
        </div>,
      )
    }
    // } else if (extension === 'mp4') {
    //   finalRender.push(
    //     <ReactPlayer
    //       url={currentFile.fileURL}
    //       config={{ file: { attributes: { controlsList: 'nodownload' } } }}
    //       // Disable right click
    //       onContextMenu={e => e.preventDefault()}
    //       playing
    //       controls
    //       width="100%"
    //       height="calc(100vh - 285px)"
    //       loop
    //       volume={1}
    //       style={{ backgroundColor: '0 0 0 0.5' }}
    //     />,
    //   )
    // } else if (
    //   extension === 'jpg' ||
    //   extension === 'jpeg' ||
    //   extension === 'png' ||
    //   extension === 'gif'
    // ) {
    //   finalRender.push(
    //     <img src={currentFile.fileURL} alt={currentFile.heading} style={{ width: '100%' }} />,
    //   )
    // }
    return (
      <>
        {/* <Scrollbar style={{ height: 'calc(100vh - 20rem)' }}> */}
        {/* <Col xs={24} md={24} lg={24} xl={24}>
          <CloseCircleOutlined onClick={this.goBack} style={{ color: 'white', fontSize: '16px' }} />
        </Col> */}
        <Row align="middle" className="headerRow">
          <Col xs={3} md={6} lg={6} xl={6}>
            <LeftOutlined onClick={this.goBack} style={{ color: 'yellow', fontSize: '16px' }} />
          </Col>
          <Col xs={18} md={12} lg={12} xl={12} className="text-center">
            <h3>{currentFile.heading}</h3>
          </Col>
          <Col xs={3} md={6} lg={6} xl={6} />
        </Row>
        <Row align="middle">
          <Col xs={24} md={24} lg={24} xl={24} className="text-center">
            {finalRender}

            {/* <PDFViewer
                document={{
                  url: currentFile.fileURL,
                }}
              /> */}
            {/* <FileViewer
                fileType={extension}
                filePath={currentFile.fileURL}
                onError={this.onError}
              /> */}
          </Col>
        </Row>
        {/* </Scrollbar> */}
      </>
    )
  }
}

export default DocumentList
