import { Col, Row, Button } from 'antd'
import Iframe from 'react-iframe'
import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Survey = ({ selected }) => {
  const requiredState = useSelector(({ registerVirtualUser }) => ({ registerVirtualUser }))
  const {
    registerVirtualUser: { source },
  } = requiredState

  console.log(requiredState, source, 'requiredState')
  // const [confirm, setConfirm] = useState(false)
  //   const confirms = () => {
  //     return (

  //     )
  //   }

  // const onSubmit = () => {
  //   setConfirm(true)
  //   // alert('hi')
  // }

  return (
    <>
      {/* {!confirm ? ( */}
      <Row>
        <Col
          span={24}
          style={{
            border: '2px solid #7fd5ea',
            borderRadius: '25px',
            // padding: '10px',
            // margin: '20px',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Col
            span={24}
            style={{
              paddingTop: '20px',
              marginBottom: '-25px',
            }}
          >
            <h4
              style={{
                fontWeight: 'bold',
                margin: '10px 0px 10px 10px',
                fontSize: '24px',
                textAlign: 'center',
              }}
            >
              Lenovo C-Suite Chat Show Survey
            </h4>
            {/* 
            <p>
              Do you know that Formula 1 is at the forefront of technology and is continuously
              supercharging the world we live in?
            </p>
            <p>
              Here is your opportunity to tap into a F1 racer&apos;s mindset and get an exclusive
              invite to a high-octane chat show.
            </p>
            <p>
              Be a part of the race to accelerate your future and win exciting F1 merchandise,
              online vouchers, etc.
            </p>
            */}
            <br />
          </Col>
          <Col span={24} style={{ padding: '20px' }}>
            <Iframe
              url={`https://form.jotform.com/222192351413042?source=${source}`}
              width="100%"
              height="calc(100vh - 200px)"
              loading="eager"
              className="theiFrame"
              display="initial"
              position="relative"
              frameborder="0"
            />
            <br />
            <Col onClick={() => selected('register')} style={{ textAlign: 'center' }}>
              <Link
                to="/virtual/auth/register"
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: 'black',
                  textDecoration: 'none',
                }}
                // className="text-primary utils__link--underlined"
              >
                <Button
                  // type="submit"
                  style={{
                    // margin: '10%',
                    backgroundColor: '#7A126B',
                    fontSize: '14px',
                    borderRadius: '10px',
                    // padding: '5px',
                    height: '35px',
                    width: '130px',
                    color: 'white',
                    fontWeight: 'bold',
                    display:'none'
                  }}
                >
                  Register Now
                </Button>
              </Link>
            </Col>
          </Col>
          {/* <div className="col-12">
            <div className="row">
              <div className="col-12 p-2">
                <h6 className="merchandise">FootBall Merchandise*:</h6>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mb-4"
                style={{ borderRight: '1px solid #46c8e1', background: '#fff' }}
              >
                <img
                  src="resources/images/img1.jpg"
                  alt="video"
                  width="110"
                  border="0"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mb-4"
                style={{ borderRight: '1px solid #46c8e1', background: '#fff' }}
              >
                <img
                  src="resources/images/img2.jpg"
                  alt="video"
                  width="120"
                  border="0"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mb-4"
                style={{ background: '#fff' }}
              >
                <img
                  src="resources/images/img3.png"
                  alt="video"
                  width="130"
                  border="0"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </div>
            </div>
          </div>
          <Col span={24} style={{ padding: '20px', backgroundColor: '#e7e7e7' }}>
            <h5 style={{ fontWeight: 'bold', margin: '0px 0px 10px 0px', fontSize: '18px' }}>
              Terms and Conditions*:
            </h5>
            <ul>
              <li>
                Final winners will be selected through lucky draw. Lenovo’s decision in terms of
                selection of winners and gifts shall be at its own discretion and will be final and
                binding.
              </li>
              <br />
              <li>
                The gift might vary based on their availability in the respective regions. The
                winner will win either a voucher or a football merchandise but not both.
              </li>
              <br />
              <li>
                Lenovo &amp; its authorized entities will not be held responsible for any damage to
                the product while in transit.
              </li>
              <br />
              <li>
                Participant acknowledges that Lenovo reserves all rights to delete/reject the entry
                at its absolute and sole discretion on any reasonable ground, including but not
                limited to: (a) received after closing time, (b) Lenovo believes that an entry
                contravenes these Terms and Conditions, including but not limited to, failure to
                acknowledge receipt of their win, or provide full and correct name and address,
                etc., and incomplete/incorrect entries from third party agents
              </li>
            </ul>
          </Col> */}
        </Col>
      </Row>
      {/* ) : ( */}
      {/* <>
        <div className="text-center">
          <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
        </div>
        <div className="text-center">
          <h2 style={{ color: '#000', marginTop: '10%' }}>
            Thank you for Submitting.

          </h2>
        </div>
      </> */}
      {/* )} */}
    </>
  )
}

export default Survey
