/* eslint-disable react/no-unescaped-entities */
import { ClockCircleOutlined,UserOutlined } from '@ant-design/icons'
import React from 'react'
import './home.less'
// import { Link } from 'react-router-dom'
// import { isMobile } from 'react-device-detect'
/* eslint-disable */
const Agenda = ({ selected, source }) => {
  return (
    <section className="locations-section-two agenda">
		<div className="auto-container">
   <div className='row'>
    <div className="location-block-two col-xl-12 col-lg-12 col-md-12 col-sm-12 wow fadeInUp animated" >
                <div className="px-4 py-3 main-footer mb-4">
                    <div className="footer-widget about-widget d-flex flex-column justify-content-between h-100">
                        <h3 className="widget-title mb-md-0">Agenda Session</h3>
                    </div>
              </div>
                {/* <!--<div className="inner-box sec-title">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="time mb-1"><ClockCircleOutlined /> &nbsp;12:30 PM</h6>
                    <h3 className="title">Registrations & Lunch</h3>
                        </div>
                    </div>
      </div>--> */}
                <div className="inner-box sec-title">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="time mb-1"><ClockCircleOutlined /> &nbsp;1:45 PM</h6>
                    <h4 className="sub-title">Welcome Note </h4>
                    <h3 className="title">Transforming India through Smarter Technology</h3>
                            <div className="speakers">
                                <p className="mb-0"><UserOutlined />&emsp;<strong>Shailendra Katyal</strong> - MD, Lenovo India</p>
                            </div>
                        </div>
                    </div>
      </div>
                <div className="inner-box sec-title">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="time mb-1"><ClockCircleOutlined /> &nbsp;2:00 PM</h6>
                    <h4 className="sub-title">Lenovo Address </h4>
                    <h3 className="title">Smarter Technology empowering a changing world </h3>
                            <div className="speakers">
                                <p className="mb-0"><UserOutlined />&emsp;<strong>Amar Babu</strong> - President, Lenovo Asia-Pacific</p>
                            </div>
                        </div>
                    </div>
      </div>
                <div className="inner-box sec-title">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="time mb-1"><ClockCircleOutlined /> &nbsp;2:20 PM</h6>
                    <h4 className="sub-title">GoI Address</h4>
                    <h3 className="title">Smarter India: Delivering the India Promise for Next Techade</h3>
                            <div className="speakers">
                                <p className="mb-0"><UserOutlined />&emsp;<strong>Amitabh Kant</strong> - G20 Sherpa, Government of India</p>
                            </div>
                        </div>
                    </div>
      </div>
                <div className="inner-box sec-title">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="time mb-1"><ClockCircleOutlined /> &nbsp;3:20 PM</h6>
                    <h4 className="sub-title">Panel Discussion</h4>
                    <h3 className="title">Innovations that define growth in the 5G Era</h3>
                            <div className="speakers">
                                <p className="mb-0"><UserOutlined />&emsp;<strong>Sumir Bhatia</strong> -  President - ISG, Lenovo Asia-Pacific</p>
              <p className="mb-0"><UserOutlined />&emsp;<strong>Santosh Vishwanathan</strong> - MD, Intel</p>
              <p className="mb-0"><UserOutlined />&emsp;<strong>Prateek Pashine</strong> - President, Reliance Jio</p>		    							
              <p className="mb-0">Moderator - &emsp;<strong>Prateek Pashine</strong> - President, Reliance Jio</p>		    							

                            </div>
                        </div>
                    </div>
      </div>
                <div className="inner-box sec-title">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="time mb-1"><ClockCircleOutlined /> &nbsp;4:05 PM</h6>
                    <h4 className="sub-title">Panel Discussion</h4>
                    <h3 className="title">Changing Paradigm with New IT in Modern Workplace</h3>
                            <div className="speakers">
                                <p className="mb-0"><UserOutlined />&emsp;<strong>Diwa Dayal</strong> - MD, Sentinel One</p>
                                <p className="mb-0"><UserOutlined />&emsp;<strong>Harnath Babu</strong> - CIO, KPMG</p>
                                <p className="mb-0">Moderator - &emsp;<strong>Shalil Gupta</strong> - Mint/VC Circle </p>		    							


                            </div>
                        </div>
                    </div>
      </div>
                <div className="inner-box sec-title">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="time mb-1"><ClockCircleOutlined /> &nbsp;4:35 PM </h6>
                    <h4 className="sub-title">Fireside chat</h4>
                    <h3 className="title">Fostering Inclusiveness in Technology</h3>
                            <div className="speakers">
                                <p className="mb-0"><UserOutlined />&emsp;<strong>Deepa Malik</strong> - Indian athlete, Paralympic winner</p>
                                <p className="mb-0"><UserOutlined />&emsp;<strong>Prateek Madhav</strong> - Co Founder &amp; CEO AssisTech Foundation (ATF)</p>
                                <p className="mb-0">Moderator - &emsp;<strong>Jaspreet Singh,</strong> - Partner and Clients and Markets Leader, Grant Thornton Bharat LLP </p>		    							
                           
                            </div>
                        </div>
                    </div>
      </div>
{/* <!--                    <div className="px-4 py-3 main-footer mb-4">
                    <div className="footer-widget about-widget d-flex flex-column justify-content-between h-100">
                        <h3 className="widget-title mb-md-0">GoI Address ( Afternoon 2PM)</h3>
                    </div>
              </div>--> */}
                <div className="inner-box sec-title">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="time mb-1"><ClockCircleOutlined /> &nbsp;5:00 PM</h6>
            <h4 className="sub-title">GOI Fireside Chat</h4>
                    <h3 className="title">Making India Digitally Empowered</h3>
                            <div className="speakers">
                                <p className="mb-0"><UserOutlined />&emsp;<strong>Rajeev Chandrasekhar</strong> - Minister of State - Information Technology</p>
                                <p className="mb-0">Moderator - &emsp;<strong> Gulveen Aulakh</strong> - Senior Assistant Editor, Mint </p>		    							
                          
                            </div>
                        </div>
                    </div>
      </div>
                <div className="inner-box sec-title">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 className="time mb-1"><ClockCircleOutlined /> &nbsp;5:30 PM</h6>
                    <h4 className="sub-title">Tech Talk</h4>
                    <h3 className="title">Reinventing Customer Experiences through Metaverse</h3>
                            <div className="speakers">
                                <p className="mb-0"><UserOutlined />&emsp; <strong>Vishal Shah</strong> - General Manager, XR (AR/VR) and Metaverse, Lenovo</p>
                                <p className="mb-0"><UserOutlined />&emsp; <strong>Rucha Nanavati</strong> - CIO,  Mahindra Group</p>
                                <p className="mb-0"><UserOutlined />&emsp; <strong>Rajesh Uppal</strong> - Executive Director &amp; CIO, Maruti Suzuki India Ltd</p>
                                <p className="mb-0"><UserOutlined />&emsp; <strong>Rajen Vagadia</strong> - VP and President, Qualcomm India &amp; SAARC</p>
                                <p className="mb-0">Moderator - &emsp;<strong>Leslie D’Monte</strong> - Executive Editor, Mint </p>		    							
                          
                                
                            </div>
                        </div>
                    </div>
      </div>
                {/* <!--<div className="px-4 py-3 main-footer mb-4">
                    <div className="footer-widget about-widget d-flex flex-column justify-content-between h-100">
                        <h3 className="widget-title mb-md-0">Ricky Kej - Onground Live performance  - 6:30 PM</h3>
                    </div>
              </div>
                <div className="px-4 py-3 main-footer mb-4">
                    <div className="footer-widget about-widget d-flex flex-column justify-content-between h-100">
                        <h3 className="widget-title mb-md-0">Networking and Dinner - 7:30 PM Onwards</h3>
                    </div>
              </div>--> */}
    </div>
  </div>
  </div></section>
  )
}

export default Agenda
