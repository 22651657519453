/* eslint-disable react/no-unescaped-entities */
import { Col, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const About = ({ selected, source }) => {
  return (
    <Row>
      {/* <Row justify="center"> */}
      <Col span={24} />
      <br />
      <Col span={24} xs={24}>
        <h3
          style={{
            fontWeight: 'bold',
            margin: '10px 0px 10px 10px',
            fontSize: '30px',
            textAlign: 'center',
          }}
        >
          About the Launch
        </h3>
      </Col>
      <Col span={24} xs={24} style={{ padding: '30px', paddingRight: '-25x' }}>
        <p style={{ fontSize: '16px', color: 'black', textAlign: 'justify' }}>
          Businesses need to enhance profitability to stay sustainable in today's business
          environment. This calls for businesses to take steps aimed at boosting revenue and profit
          growth. In such a scenario, Lenovo, in association with AMD, is instrumental in rolling
          out innovative solutions that help solve customer's problems and bring a paradigm shift in
          the way we live, learn and work.
        </p>
        <br />
        <p style={{ fontSize: '16px', color: 'black', textAlign: 'justify' }}>
          In line with this, we are all set to launch our much-awaited{' '}
          <strong>Lenovo | Tech World India Edition,</strong>&nbsp; curated by IDC, on{' '}
          {source === 'ANZ-ANZ' || source === 'ANZ-AMD' || source === 'ANZ-MARKETO' || source === 'ANZ-SOCIAL'
            ? 'February 23, 2023'
            : 'February 22, 2023'}
          . The Playbook will be launched at the next edition of the{' '}
          <strong> Lenovo C-Suite Chat Show: Smarter Leads the Future.</strong>
        </p>
        <br />
        <Col span={24}>
          <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>
            Top reasons to get your hands on the CIO Technology Playbook 2023
          </h3>
          <br />
          <Row>
            <Col span={8} style={{ textAlign: 'center' }}>
              <img src="resources/images/Icon1.png" alt="banner" style={{ textAlign: 'center' }} />
              <br />
              <br />
              <h4 style={{ textAlign: 'center' }}>
                Access In-depth Research
                <br /> Involving 900 CIOs
              </h4>
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              <img src="resources/images/Icon2.png" alt="banner" style={{ textAlign: 'center' }} />
              <br />
              <br />
              <h4 style={{ textAlign: 'center' }}>
                Discover CIO Technology
                <br /> Trends for 2023
              </h4>
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              <img src="resources/images/Icon3.png" alt="banner" style={{ textAlign: 'center' }} />
              <br />
              <br />
              <h4 style={{ textAlign: 'center' }}>
                Explore Top CIO
                <br /> Priorities
              </h4>
            </Col>
          </Row>
        </Col>
        <br />
        <br />

        {/* <Col span={24}>
          <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>
            Sneak Peek into the Insights from Technology Playbook 2023
          </h3>
        </Col> */}
        <Col span={24}>
          <Row>
            <Col span={4} />
            <Col span={16} style={{ textAlign: 'center' }}>
              <h3
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  backgroundColor: '#7A126B',
                  color: 'white',
                  borderRadius: '5px',
                  padding: '3px 7px',
                }}
              >
                Sneak Peek into the Insights from Technology Playbook 2023
              </h3>
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={8} style={{ textAlign: 'center' }}>
              <h1 style={{ textAlign: 'center', fontWeight: 'bold', color: '#7A126B' }}>31.7% </h1>

              <h5 style={{ textAlign: 'center' }}>
                of CIOS prioritize cost optimization and <br />
                savings in these uncertain times
              </h5>
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              <h1 style={{ textAlign: 'center', fontWeight: 'bold', color: '#7A126B' }}>32.3% </h1>{' '}
              <h5 style={{ textAlign: 'center' }}>
                of CIOs deem superior customer experience <br /> as one of the top priorities
              </h5>
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              <h1 style={{ textAlign: 'center', fontWeight: 'bold', color: '#7A126B' }}>39.2% </h1>{' '}
              <h5 style={{ textAlign: 'center' }}>
                of CIOS prioritize the modernization of
                <br /> legacy applications for businesses
              </h5>
            </Col>
          </Row>
        </Col>
        {/* <p style={{ fontSize: '16px', color: 'black', textAlign: 'justify' }}>
          Block your calendars and find out how smarter can change the game for your enterprise with
          ace footballer Marco Materazzi, renowned sports anchor John Dykes and Lenovo leader Sumir
          Bhatia.
        </p> */}
        {/* <p style={{ fontSize: '16px', color: 'black', textAlign: 'justify', fontWeight: 'bold' }}>
          Register for Lenovo C-Suite Chat Show: Smarter Changes the Game, take part in fun
          engagements and you could win exciting football merchandise{' '}
        </p> */}
      </Col>
      <br />
      <Col span={24} style={{ padding: '30px', paddingRight: '-25x' }}>
        <h3 style={{ fontWeight: 'bold' }}>Key Discussion Topics </h3>
        <ul style={{ paddingLeft: '18px' }}>
          <li style={{ fontSize: '16px' }}>Top business and CIO priorities for 2023</li>
          <li style={{ fontSize: '16px' }}>The importance of digital infrastructure</li>
          <li style={{ fontSize: '16px' }}>The future of as-a-Service</li>
          <li style={{ fontSize: '16px' }}>The Challenges and best practices of Data Management</li>
          <li style={{ fontSize: '16px' }}>Industry use cases of Edge and AI, and much more!</li>
        </ul>
      </Col>
      <br />
      <br />
      <br />
      <Col span={24} style={{ padding: '30px', paddingRight: '-25x', textAlign: 'center' }}>
        <h3 style={{ fontWeight: 'bold' }}>
          Mark your calendar for the biggest virtual event of 2023.
        </h3>
      </Col>
      <br />
      <br />
      <Col span={24} onClick={() => selected('register')} style={{ textAlign: 'center' }}>
        <Link
          to={source ? `/virtual/auth/register?source=${source}` : '/virtual/auth/register'}
          style={{
            fontSize: '14px',
            fontWeight: 'bold',
            color: 'white',
            textDecoration: 'none',
          }}
          // className="text-primary utils__link--underlined"
        >
          {/* <Button
            style={{
              backgroundColor: '#7A126B',
              fontSize: '22px',
              borderRadius: '10px',
              height: '50px',
              width: '200px',
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            Register Now
          </Button> */}
        </Link>
      </Col>
      <Col span={24} />
      <br />
    </Row>
  )
}

export default About
