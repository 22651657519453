import localeAntd from 'antd/es/locale/ja_JP'

const messages = {
  'virtualMenu.home': 'ホームホーム',
  'virtualMenu.back': 'バック',
  'virtualMenu.agenda': '議題',
  'virtualMenu.chat': 'チャット',
  'virtualMenu.navigation': 'ナビゲーション',
  'virtualMenu.briefcase': 'ブリーフケース',
  'virtualMenu.speakers': 'スピーカー',
  'virtualMenu.helpdesk': 'ヘルプデスク',
  'virtualMenu.mypoints': 'マイポイント',
  'virtualMenu.meetings': 'ミーティング',
  'virtualMenu.leaderboard': 'リーダーボード',
  'virtualMenu.eshop': 'Eショップ',
  'topMenu.logout': 'ログアウト',
  'chat.heading': 'チャット',
  'chat.subheading': '新しい会話を始める',
  'chat.attendees': '参加者',
  'chat.requests': 'リクエスト',
  'chat.messages': 'メッセージ',
  'chat.placeholder': 'ここにあなたのメッセージを書いてください',
  'streamFeature.heading': 'インタラクションゾーン',
  'streamFeature.liveChat': 'ライブチャット',
  'streamFeature.qna': 'Q＆Aパネル',
  'streamFeature.polling': 'ライブポーリング',
  'streamFeature.qnaplaceholder': 'ここで質問してください。',
  'general.resourceCenter': 'リソース・センター',
  'bottomMenu.groupVideo': 'ライブミーティングに参加する',
  'bottomMenu.enable': '下部ナビゲーションを有効にする',
  'bottomMenu.chat': 'チャット',
  'bottomMenu.intro': '前書き',
  'bottomMenu.meeting': '会議',
  'bottomMenu.dropCard': 'ドロップカード',
  'bottomMenu.exhibitors': '出展者',
  'bottomMenu.socialMedia': 'ソーシャルメディア',
  'bottomMenu.share': 'シェア',
  'bottomMenu.rating': '評価',
  'bottomMenu.download': 'ドックをダウンロード',
  home: 'ホーム',
  about: 'イベント概要',
  agenda: 'アジェンダ',
  speakers: 'スピーカー',
  survey: 'アンケート',
  register: '今すぐ登録する',
  login: 'ログイン',
  ifAlready: '（登録済の方）',
  submit: '送信する',
  newUser: '新しいユーザー',
  email: 'メールアドレス*',
  fname: '名*',
  lname: '姓*',
  company: '会社名*',
  designation: '役職*',
  mob: '携帯番号',
  country: '国*',
  city: '都市*',
  areYouLenovo: '現在、レノボチャンネルパートナーですか？*',
  yes: 'はい',
  no: 'いいえ',
  privacy: 'プライバシーポリシー*',
  unsubscribe: '登録を解除する',
  warning1:
    'このフォームを送信することにより、電子メールを介してマーケティングコミュニケーションを受信することに同意したことになります',
    completeForm:'このフォームに記入して、LenovoC-Suiteチャットショーに登録してください',
    requiredfield:'(*は必須)',
    step:'ステップ',
    game:'ゲーム',
    ist:'インド標準時',
    sgt:'シンガポール標準時',
    aedt:'オーストラリア東部標準時',
    month:'2022年9月21日',
    day:'残り日数'
}

export default {
  locale: 'ja-JP',
  localeAntd,
  messages,
}
