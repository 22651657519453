import React from 'react'
import { Col, Button } from 'antd'
import { Link } from 'react-router-dom'

import './home.less'

const Speaker = ({ selected, source }) => {
  console.log(source, 'source')
  return (
    <>
      <div className="content_div_speacker">
        <div className="maintextbox">
          <h3
            style={{
              fontWeight: 'bold',
              margin: '10px 0px 40px 10px',
              fontSize: '30px',
              textAlign: 'center',
            }}
          >
            Speakers
          </h3>

          <div className="speaker_container pt-2">
            <h3>
              <strong>Launch Session:</strong> Smarter Leads the Future: 45 Mins{' '}
            </h3>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                {/* <h4>&nbsp; </h4> */}
                <div className="speaker_wrap">
                  <img
                    src="resources/images/SumirBhatiaLenovo.png"
                    className="img-fluid"
                    alt="Sumir Bhatia"
                  />
                  <p className="m-0">
                    <strong>Sumir Bhatia</strong>
                    <br />
                    President, Lenovo ISG, Asia Pacific
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                {/* <h4 style={{ fontWeight: 'bold' }}>Panelists:</h4> */}
                <div className="speaker_wrap">
                  <img
                    src="resources/images/PeterChambers.png"
                    className="img-fluid"
                    alt="Peter Chambers"
                  />
                  <p className="m-0">
                    <strong>Peter Chambers</strong>
                    <br /> MD Sales - AMD,
                    <br />
                    APAC & Japan
                  </p>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12"
              // style={{ padding: '20px' }}
              >
                {/* <h4 className="text-center">Moderator:</h4> */}
                <div className="speaker_wrap">
                  <img
                    src="resources/images/RajnishArora.png"
                    className="img-fluid"
                    alt="Rajnish Arora"
                  />
                  <p className="m-0">
                    <strong>Rajnish Arora</strong>
                    <br />
                    Vice President
                    <br /> Analyst IDC,Asia Pacific
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="borderbot mt-4" />
          {source === 'ANZ-ANZ' || source === 'ANZ-AMD' || source === 'ANZ-MARKETO' || source === 'ANZ-SOCIAL' ? (
            <div className="speaker_container mt-5">
              <h3>
                <strong>Region Breakout Session:</strong> Smarter Insights Fuelling Growth in ANZ:
                30 Mins
              </h3>

              <div className="row mt-4  mb-4">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mb-4">
                  {/* <h4 style={{ fontWeight: 'bold' }}>Panelists:</h4> */}
                  <div className="speaker_wrap ">
                    <img src="resources/images/manu.png" className="img-fluid" alt="Kumar Mitra" />
                    <p className="m-0">
                      <strong>Manu Mehra,</strong>
                      <br />
                      Managing Director,
                      <br />
                      Lenovo ISG, ANZ
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 ">
                  {/* <h4>&nbsp; </h4> */}
                  <div className="speaker_wrap">
                    <img
                      src="resources/images/PeterChambers.png"
                      className="img-fluid"
                      alt="Peter Chambers"
                    />
                    <p className="m-0">
                      <strong>Peter Chambers</strong>
                      <br /> MD Sales - AMD,
                      <br />
                      APAC & Japan
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 ">
                  <div className="speaker_wrap">
                    <img
                      src="resources/images/Danny.png"
                      className="img-fluid"
                      alt="Sinisa Nikolic"
                    />
                    <p className="m-0">
                      <strong>Danny Beck,</strong>
                      <br />
                      Senior Marketing Manager
                      <br />
                      Lenovo ISG, ANZ
                    </p>
                  </div>
                </div>

                {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="speaker_wrap ">
                  <h4 className="text-center">Moderator:</h4>
                  <img
                    src="resources/images/dummy.png"
                    className="img-fluid"
                    alt="Marjet Andriesse"
                  />
                  <p className="m-0">
                    <strong>Marjet Andriesse</strong>
                    <br />
                    Senior VP, APAC, Red Hat
                  </p>
                </div>
              </div> */}
              </div>
            </div>
          ) : (
            <>
              <div className="speaker_container mt-5">
                <h3>
                  <strong> Region Breakout Session 1:</strong> Smarter Insights Fuelling Growth in
                  ASEAN: 30 Mins
                </h3>

                <div className="row mt-4  mb-4">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mb-4">
                    {/* <h4 style={{ fontWeight: 'bold' }}>Panelists:</h4> */}
                    <div className="speaker_wrap ">
                      <img
                        src="resources/images/KumarMitra.png"
                        className="img-fluid"
                        alt="Kumar Mitra"
                      />
                      <p className="m-0">
                        <strong>Kumar Mitra</strong>
                        <br />
                        <br />
                        Regional General Manager,
                        <br />
                        Central Asia Pacific, Lenovo ISG
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 ">
                    {/* <h4>&nbsp; </h4> */}
                    <div className="speaker_wrap">
                      <img
                        src="resources/images/Alexei.png"
                        className="img-fluid"
                        alt="Sinisa Nikolic"
                      />
                      <p className="m-0">
                        <strong>Alexey Nevolokin</strong>
                        <br />
                        Director - Commercial Business, APAC & Japan
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 ">
                    <div className="speaker_wrap">
                      <img
                        src="resources/images/Sinisaspeaker.png"
                        className="img-fluid"
                        alt="Sinisa Nikolic"
                      />
                      <p className="m-0">
                        <strong>Sinisa Nikolic</strong>
                        <br />
                        Director HPC &amp; AI Segment <br /> Lenovo ISG AP
                      </p>
                    </div>
                  </div>

                  {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="speaker_wrap ">
                  <h4 className="text-center">Moderator:</h4>
                  <img
                    src="resources/images/dummy.png"
                    className="img-fluid"
                    alt="Marjet Andriesse"
                  />
                  <p className="m-0">
                    <strong>Marjet Andriesse</strong>
                    <br />
                    Senior VP, APAC, Red Hat
                  </p>
                </div>
              </div> */}
                </div>
              </div>
              <div className="speaker_container mt-5">
                <h3>
                  <strong>Region Breakout Session 2:</strong> Smarter Insights Fuelling Growth in
                  India: 30 Mins
                </h3>

                <div className="row mt-4  mb-4">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mb-4">
                    {/* <h4 style={{ fontWeight: 'bold' }}>Panelists:</h4> */}
                    <div className="speaker_wrap ">
                      <img
                        src="resources/images/AmitLuthra.png"
                        className="img-fluid"
                        alt="Amit Luthra"
                      />
                      <p className="m-0">
                        <strong>Amit Luthra</strong>
                        <br />
                        Regional General Manager
                        <br />
                        Lenovo ISG, India
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <div className="speaker_wrap ">
                      {/* <h4 className="text-center">Moderator:</h4> */}
                      <img
                        src="resources/images/VinaySinha.png"
                        className="img-fluid"
                        alt="Vinay Sinha"
                      />
                      <p className="m-0">
                        <strong>Vinay Sinha</strong>
                        <br />
                        Managing Director - AMD,
                        <br />
                        India
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    {/* <h4>&nbsp; </h4> */}
                    <div className="speaker_wrap ">
                      <img
                        src="resources/images/sachinbhatia.png"
                        className="img-fluid"
                        alt="Forrest Norrod"
                      />
                      <p className="m-0">
                        <strong>Sachin Bhatia,</strong>
                        <br />
                        Chief Marketing Officer
                        <br />
                        Lenovo ISG, Asia Pacific
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="borderbot mt-4" />
        </div>
        <br />
        <Col onClick={() => selected('register')} style={{ textAlign: 'center' }}>
          <Link
            to={source ? `/virtual/auth/register?source=${source}` : '/virtual/auth/register'}
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: 'white',
              textDecoration: 'none',
            }}
          // className="text-primary utils__link--underlined"
          >
            <Button
              // type="submit"
              style={{
                // margin: '10%',
                backgroundColor: '#7A126B',
                fontSize: '22px',
                borderRadius: '10px',
                // padding: '5px',
                height: '50px',
                width: '200px',
                color: 'white',
                fontWeight: 'bold',
                display:'none'
              }}
            >
              Register Now
            </Button>
          </Link>
        </Col>
        <Col span={24} />
      </div>
    </>
  )
}

export default Speaker
