import React, { Component } from 'react'
import { Form, Input, Button, Col } from 'antd'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import actions from 'redux/virtual/user/actions'
import styles from './style.module.scss'
import './safaripasswordfix.less'

// import LoginForm from './LoginForm'

/* eslint-disable */
// const { TabPane } = Tabs
// const { Option } = Select

@connect(({ virtualUser, virtualSettings, locales }) => ({ virtualUser, virtualSettings, locales }))
class Login extends Component {
  state = {
    countryCode: '+91',
  }

  toggleFullScreen = elem => {
    try {
      // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (
        (document.fullScreenElement !== undefined && document.fullScreenElement === null) ||
        (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) ||
        (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
        (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)
      ) {
        if (elem.requestFullScreen) {
          elem.requestFullScreen()
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen()
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  onSubmit = values => {
    // event.preventDefault()
    const {
      dispatch,
      virtualUser: { mode },
      virtualSettings: { isDefaultFullScreen },
    } = this.props
    const { countryCode } = this.state
    dispatch({
      type: actions.LOGIN,
      payload: {
        numberOrEmail: mode === 'email' ? values.email : `${countryCode}${values.phone}`,
        password: '123456',
      },
    })
    dispatch({
      type: actions.SET_STATE,
      payload: {
        loading: true,
      },
    })

    if (isDefaultFullScreen) {
      this.toggleFullScreen(document.body)
    }
  }

  callback = key => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        mode: key,
      },
    })
  }

  gotoReg = () => {
    const { onSelected } = this.props
    if (onSelected) {
      onSelected('register')
    }
  }

  render() {
    const {
      virtualUser: { loading }, locales: { selectedLocale, availableLocales, isLocaleSwitcher }

    } = this.props
    const lang = availableLocales[selectedLocale]

    const selectedLang = lang.associatedLocale.messages
    console.log(lang, selectedLang, isLocaleSwitcher, 'llang')
    // const { backgroundNumber } = this.state
    // const prefixSelector = (
    //   <Form.Item name="prefix" noStyle>
    //     <Select
    //       defaultValue="91"
    //       style={{ width: 70 }}
    //       onChange={event => {
    //         this.setState({ countryCode: `+${event}` })
    //       }}
    //     >
    //       <Option value="91">+91</Option>
    //       <Option value="1">+1</Option>
    //       <Option value="44">+44</Option>
    //     </Select>
    //   </Form.Item>
    // )
    // const helperMessage = (
    //   <p>
    //     For any registration and login issues please reach out at following email id -{' '}
    //     <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=LoginIssue`}>
    //       {process.env.REACT_APP_SUPPORT_EMAIL}
    //     </a>
    //     <br />
    //     <br />
    //     For any other IT-related issues please reach out to your Local IT Helpdesk.
    //   </p>
    // )
    return (
      // <Row gutter={[16, 16]}>
      <>
        <Helmet title="Login" />
        <Col span={24} style={{ padding: '10px', }}>
          {/* <div className={styles.theform}> */}
          {/* <div className={styles.header}>
            <h2 className={`${styles.animation} ${styles.a1}`}>Login</h2>
            <h4 className={`${styles.animation} ${styles.a2}`}>Log in to your account using email and password</h4>
          </div> */}
          <div className={styles.form}>
            <p className={styles.formTitle}>Login</p>
            <Form
              name="login"
              onFinish={this.onSubmit}
              scrollToFirstError
              layout="vertical"
              hideRequiredMark
            >
              {/* <Tabs onChange={this.callback} type="card">
                <TabPane tab="Email" key="email"> */}
              <Form.Item
                label={lang.name === 'Japanese' ? selectedLang.email : 'Email ID'}
                name="email"
                rules={[{ required: true, message: 'Please input your Email ID!' }]}
              >
                <Input placeholder={lang.name === 'Japanese' ? selectedLang.email : 'Email ID'} />
              </Form.Item>
              {/* </TabPane> */}
              {/* <TabPane tab="Mobile No." key="mobile">
                <Form.Item
                  name="phone"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: 'Phone number required',
                    },
                  ]}
                >
                  <Input
                    placeholder="Your Registered Mobile Number"
                    addonBefore={prefixSelector}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </TabPane>
            </Tabs> */}

              {/* <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Kindly Enter your Password" />
              </Form.Item> */}

              {/* <Form.Item name="remember" valuePropName="checked" initialvalue="true">
                <Checkbox>Remember me</Checkbox>
                <Link
                  to="/virtual/auth/forgot"
                  className="utils__link--blue utils__link--underlined pull-right"
                >
                  Forgot password?
                </Link>
              </Form.Item> */}
              {/* <Form.Item
                name="confirmtrue"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error("Testimony required.")),
                  },
                ]}
              >
                <Checkbox>I agree that I have read the <a rel="noopener noreferrer" target="_blank" href={`${window.location.origin}/resources/documents/PrivacyPolicyNeo.pdf`} className="text-primary utils__link--underlined"> Privacy Policy </a> and I am willing to participate in the GBS Annual Day 2021 event by entering my Siemens email ID.</Checkbox>
              </Form.Item> */}
              <div className="form-actions">
                <Button
                  type="primary"
                  className="width-150 mr-4"
                  htmlType="submit"
                  loading={loading}
                >
                  {lang.name === 'Japanese' ? selectedLang.submit : 'Submit'}
                </Button>
                <span className="register-link black-text pull-right" onClick={this.gotoReg}>
                  {lang.name === 'Japanese' ? selectedLang.newUser : 'Not Registered'}? &nbsp;
                  <Link
                    to="/virtual/auth/register"
                    className="text-primary utils__link--underlined"
                  >
                    {lang.name === 'Japanese' ? selectedLang.register : 'Register Now'}
                  </Link>
                </span>
                <br />
                {/* <span className="register-link black-text pull-right">
                  <Link to="/virtual/auth/forgot" className="text-primary utils__link--underlined">
                    Forgot Password?
                  </Link>
                  &nbsp;&nbsp;if you don&#39;t have an account
                </span> */}
                <br />
                <br />
                {/* <Alert
                  message="Trouble Logging in?"
                  description={helperMessage}
                  type="info"
                  showIcon
                /> */}
              </div>
            </Form>
          </div>
          {/* </div> */}
        </Col>
      </>
      // </Row>
    )
  }
}

export default Login
