import React from 'react'
import { Modal, Row, Col, Skeleton } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleTwoTone } from '@ant-design/icons'
import Scrollbars from 'react-custom-scrollbars'
import actions from 'redux/virtual/event/actions'
import './leaderboard.less'
import LeaderBoardTableCard from './LeaderBoardTableCard'
import LeaderBoardSingleCard from './LeaderBoardSingleCard'
import UserScore from './UserScore'

/* eslint-disable react/jsx-indent */
const dummyRanker = {
  name: 'NA',
  score: 0,
  user_id: 'NA',
  my_rank: 2,
  points: 0,
}
const mapStateToProps = ({ virtualSettings, virtualEvent }) => ({
  virtualSettings,
  virtualEvent,
})

@connect(mapStateToProps)
class LeaderBoard extends React.Component {
  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isLeaderBoardOpen: false,
        isLeaderBoardCalled: false,
      },
    })
  }

  render() {
    const {
      virtualEvent: { isLeaderBoardOpen, leaderBoardData, leaderBoardLoading },
    } = this.props
    const { userScore } = leaderBoardData
    const leaderBoard = leaderBoardData.leaderboard !== undefined ? leaderBoardData.leaderboard : []
    const firstRanker = leaderBoard.length > 0 ? leaderBoard[0] : { ...dummyRanker, my_rank: 1 }
    const secondRanker = leaderBoard.length > 1 ? leaderBoard[1] : { ...dummyRanker, my_rank: 2 }
    const thirdRanker = leaderBoard.length > 2 ? leaderBoard[2] : { ...dummyRanker, my_rank: 3 }
    const restRanker = leaderBoard.slice(3, 20)
    return (
      <div>
        <Modal
          visible={isLeaderBoardOpen}
          style={{
            top: 0,
            // display: 'flex',
            maxHeight: '100vh',
            overflow: 'hidden',
          }}
          wrapClassName="leaderBoardModel"
          maskClosable={false}
          mask
          width="60rem"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
          // destroyOnClose
        >
          <Row id="modalHeader" className="modalHeader">
            <Col span={24} className="text-center">
              <h2 style={{ color: 'black' }}>Leaderboard</h2>
            </Col>
          </Row>
          <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 25rem)">
            <Row gutter={24} id="modalContent" className="modalContent" style={{ padding: '0' }}>
              <Col span={24}>
                <UserScore restRankerDetails={{ ...userScore, userItSelf: true }} />
              </Col>
            </Row>
            <Row gutter={24} id="modalContent" className="modalContent">
              <Col span={24}>
                <Skeleton loading={leaderBoardLoading} active>
                  {/* <LeaderBoardTableCard restRankerDetails={{ ...userScore, userItSelf: true }} /> */}
                  <Row gutter={24} id="topRanker_container" className="modalHeader">
                    <Col span={24} className="text-center">
                      <Row>
                        <Col xs={24} sm={24} md={8} id="second_rank_card">
                          <LeaderBoardSingleCard rankersDetails={secondRanker} />
                        </Col>
                        <Col xs={24} sm={24} md={8} id="first_rank_card">
                          <LeaderBoardSingleCard rankersDetails={firstRanker} />
                        </Col>
                        <Col xs={24} sm={24} md={8} id="third_rank_card">
                          <LeaderBoardSingleCard rankersDetails={thirdRanker} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {restRanker.map(item => {
                    return (
                      <LeaderBoardTableCard restRankerDetails={{ ...item, userItSelf: false }} />
                    )
                  })}
                  <br />
                </Skeleton>
              </Col>
            </Row>
          </Scrollbars>
        </Modal>
      </div>
    )
  }
}

export default LeaderBoard
