import React from 'react'
import { FormattedMessage } from 'react-intl'

export async function getLeftMenuData(isVirtualMobileView, navigationData, isExhibitor) {
  let isLogout = ''
  if (isVirtualMobileView) {
    isLogout = {
      title: 'Logout',
      key: 'logout',
      icon: 'icmn icmn-switch',
    }
  }
  let thefinalLeg = {}
  if (isExhibitor) {
    thefinalLeg = {
      title: 'Leads',
      key: 'leads',
      icon: 'icmn icmn-user-check',
    }
  }

  // let theDirectory = {}
  // if (!isExhibitor) {
  //   theDirectory = {
  //     title: 'Directory',
  //     key: 'directory',
  //     icon: 'icmn icmn-book',
  //   }
  // }

  console.log('is logout?')
  console.log(isLogout)

  return [{},
    {
      title: <FormattedMessage id="virtualMenu.back" />,
      key: 'back',
      icon: 'icmn icmn-undo2',
    },
    {
      title: <FormattedMessage id="virtualMenu.home" />,
      key: 'home',
      icon: 'icmn icmn-home',
    },
    // {
    //   title: <FormattedMessage id="virtualMenu.agenda" />,
    //   key: 'agenda',
    //   icon: 'icmn icmn-calendar',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.auditorium" />,
    //   key: 'auditorium',
    //   icon: 'icmn icmn-library',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.photobooth" />,
    //   key: 'photobooth',
    //   icon: 'icmn icmn-camera',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.chat" />,
    //   key: 'sidebar',
    //   icon: 'icmn icmn-bubbles2',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.navigation" />,
    //   key: 'navigation',
    //   icon: 'icmn icmn-compass2',
    //   children: navigationData,
    // },

    // {
    //   title: <FormattedMessage id="virtualMenu.briefcase" />,
    //   key: 'briefcase',
    //   icon: 'icmn icmn-briefcase',
    // },

    // {
    //   title: <FormattedMessage id="virtualMenu.helpdesk" />,
    //   key: 'helpdesk',
    //   icon: 'icmn icmn-info',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.mypoints" />,
    //   key: 'pointredeem',
    //   icon: 'icmn icmn-coin-dollar',
    // },
    // {
    // {
    //   title: <FormattedMessage id="virtualMenu.speakers" />,
    //   key: 'speakers',
    //   icon: 'icmn icmn-man',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.meetings" />,
    //   key: 'meeting',
    //   icon: 'icmn icmn-user-tie',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.leaderboard" />,
    //   key: 'leaderBoard',
    //   icon: 'icmn icmn-users',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.eshop" />,
    //   key: 'eShop',
    //   icon: 'icmn icmn-cart',
    // },
    // theDirectory,
    isLogout,
    thefinalLeg,
  ]
}
export async function getTopMenuData(isVirtualMobileView, navigationData, isExhibitor) {
  console.log('in menu top')
  console.log(isVirtualMobileView)
  let isLogout = ''
  if (isVirtualMobileView) {
    isLogout = {
      title: 'Logout',
      key: 'logout',
      icon: 'icmn icmn-switch',
    }
  }
  let thefinalLeg = {}
  if (isExhibitor) {
    thefinalLeg = {
      title: 'Leads',
      key: 'leads',
      icon: 'icmn icmn-user-check',
    }
  }

  // let theDirectory = {}
  // if (!isExhibitor) {
  //   theDirectory = {
  //     title: 'Directory',
  //     key: 'directory',
  //     icon: 'icmn icmn-book',
  //   }
  // }
  return [{},
    // {
    //   title: <FormattedMessage id="virtualMenu.back" />,
    //   key: 'back',
    //   icon: 'icmn icmn-undo2',
    // },
    {
      title: <FormattedMessage id="virtualMenu.home" />,
      key: 'home',
      icon: 'icmn icmn-home',
    },
    // {
    //   title: <FormattedMessage id="virtualMenu.agenda" />,
    //   key: 'agenda',
    //   icon: 'icmn icmn-calendar',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.auditorium" />,
    //   key: 'auditorium',
    //   icon: 'icmn icmn-library',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.photobooth" />,
    //   key: 'photobooth',
    //   icon: 'icmn icmn-camera',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.chat" />,
    //   key: 'sidebar',
    //   icon: 'icmn icmn-bubbles2',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.navigation" />,
    //   key: 'navigation',
    //   icon: 'icmn icmn-compass2',
    //   children: navigationData,
    // },

    // {
    //   title: <FormattedMessage id="virtualMenu.briefcase" />,
    //   key: 'briefcase',
    //   icon: 'icmn icmn-briefcase',
    // },

    // {
    //   title: <FormattedMessage id="virtualMenu.helpdesk" />,
    //   key: 'helpdesk',
    //   icon: 'icmn icmn-info',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.mypoints" />,
    //   key: 'pointredeem',
    //   icon: 'icmn icmn-coin-dollar',
    // },
    // {
    // {
    //   title: <FormattedMessage id="virtualMenu.speakers" />,
    //   key: 'speakers',
    //   icon: 'icmn icmn-man',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.meetings" />,
    //   key: 'meeting',
    //   icon: 'icmn icmn-user-tie',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.leaderboard" />,
    //   key: 'leaderBoard',
    //   icon: 'icmn icmn-users',
    // },
    // {
    //   title: <FormattedMessage id="virtualMenu.eshop" />,
    //   key: 'eShop',
    //   icon: 'icmn icmn-cart',
    // },
    // Object.keys(theDirectory).length !== 0 ? theDirectory : '',
    thefinalLeg,
    isLogout,
  ]
}
